import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../../utils/context";
library.add(fas);

const Sidebar = () => {
  const { isAllow } = useContext(Context);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  }, [location.pathname]);

  const [active, setActive] = useState("0");

  const [activeAccordionItem, setActiveAccordionItem] = useState(null);
  const [activeInnerListItem, setActiveInnerListItem] = useState(null);

  const handleAccordionClick = (itemKey) => {
    setActiveAccordionItem(itemKey === activeAccordionItem ? null : itemKey);
  };

  const handleInnerListItemClick = (itemId) => {
    setActiveInnerListItem(itemId === activeInnerListItem ? null : itemId);
  };

  return (
    <>
      <section style={{ width: isOpen ? "250px" : "50px" }} className="sidebar">
        <div className="top_section">
          {/* <h1
            style={{ display: isOpen ? "block" : "none" }}
            className="logo ms-2"
          >
            IT PULSE
          </h1> */}
          {isOpen && (
            <Link
              to="/advanceDashboard"
              className="text-decoration-none rounded"
            >
              <h3 className="Itlogtitle">IT Pulse</h3>
            </Link>
          )}
          <div
            style={{ marginLeft: isOpen ? "30px" : "22px" }}
            className="bars"
          >
            <FaBars onClick={() => setIsOpen(!isOpen)} />
          </div>
        </div>

        <ul className="list-unstyled ps-0">
          <Accordion defaultActiveKey={active}>
            <Accordion.Item
              eventKey="1"
              className={`mb-1 ${activeAccordionItem === "1" ? "active" : ""}`}
              onClick={() => handleAccordionClick("1")}
            >
              <li
                className={`${
                  isOpen
                    ? headerText.includes("/advanceDashboard")
                      ? "active block"
                      : "none"
                    : "none"
                }`}
                onClick={() => handleInnerListItemClick("advanceDashboard")}
              >
                <Link
                  to="/advanceDashboard"
                  className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                >
                  <p
                    // onClick={() => setIsOpen(!isOpen)}
                    style={{ display: isOpen ? "block" : "none" }}
                  >
                    Dashboard
                  </p>
                </Link>
              </li>
              <Accordion.Body style={{ display: isOpen ? "block" : "none" }}>
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {/* <li
                    className={`${isOpen
                      ? headerText.includes("/privateDashboard")
                        ? "active block"
                        : "none"
                      : "none"
                      }`}
                    onClick={() => handleInnerListItemClick("privateDashboard")}
                  >
                    <Link
                      to="/privateDashboard"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded "
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Private Dashboard
                      </p>
                    </Link>
                  </li> */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* ****************************************************************Settings ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="2"
              className={`mb-1 ${activeAccordionItem === "2" ? "active" : ""}`}
              onClick={() => handleAccordionClick("2")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Settings
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/country")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("country")}
                  >
                    <Link
                      to="/masters/country"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Country
                      </p>
                    </Link>
                  </li>

                  {/* State */}

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/state")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("state")}
                  >
                    <Link
                      to="/masters/state"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        State
                      </p>
                    </Link>
                  </li>

                  {/* City */}

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/city")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("city")}
                  >
                    <Link
                      to="/masters/city"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        City
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/banner")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("banner")}
                  >
                    <Link
                      to="/masters/banner"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Banner
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/home_part_two")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("home_part_two")}
                  >
                    <Link
                      to="/masters/home_part_two"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Our About
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/solution")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("solution")}
                  >
                    <Link
                      to="/masters/solution"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        solution
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/our_brand")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("our_brand")}
                  >
                    <Link
                      to="/masters/our_brand"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Our Brand
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/category")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("category")}
                  >
                    <Link
                      to="/masters/category"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Product Category
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/home_lower")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("home_lower")}
                  >
                    <Link
                      to="/masters/home_lower"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Service Banner
                      </p>
                    </Link>
                  </li>

                  {/* <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/common_type")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("common_type")}
                  >
                    <Link
                      to="/masters/common_type"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Product Common Type
                      </p>
                    </Link>
                  </li> */}

                  {/* <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/uses")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("uses")}
                  >
                    <Link
                      to="/masters/uses"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Product Uses
                      </p>
                    </Link>
                  </li> */}

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/subCategory")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("subCategory")}
                  >
                    <Link
                      to="/masters/subCategory"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Products
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/product_spotlight")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() =>
                      handleInnerListItemClick("product_spotlight")
                    }
                  >
                    <Link
                      to="/masters/product_spotlight"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Product Spotlight
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/happy_customers")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("happy_customers")}
                  >
                    <Link
                      to="/masters/happy_customers"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Happy Customers
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/application")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("application")}
                  >
                    <Link
                      to="/masters/application"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Application
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/ourclients")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("ourclients")}
                  >
                    <Link
                      to="/masters/ourclients"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Our Clients
                      </p>
                    </Link>
                  </li>

                  {/* <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/ourstrength")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("ourstrength")}
                  >
                    <Link
                      to="/masters/ourstrength"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Our Strength
                      </p>
                    </Link>
                  </li> */}

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/support")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("support")}
                  >
                    <Link
                      to="/masters/support"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        FAQ
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/director_profile")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("director_profile")}
                  >
                    <Link
                      to="/masters/director_profile"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Director Profile
                      </p>
                    </Link>
                  </li>
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/newsevents")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("newsevents")}
                  >
                    <Link
                      to="/masters/newsevents"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        News & Events
                      </p>
                    </Link>
                  </li>
                </ul>
                {/* </div> */}
              </Accordion.Body>
            </Accordion.Item>
            {/* ****************************************************************Customer Management ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="3"
              className={`mb-1 ${activeAccordionItem === "3" ? "active" : ""}`}
              onClick={() => handleAccordionClick("2")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Customer Management
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/customermanegment/queryinquiry")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("queryinquiry")}
                  >
                    <Link
                      to="/customermanegment/queryinquiry"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        QueryInquiry
                      </p>
                    </Link>
                  </li>
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/customermanegment/contact_us")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("contact_us")}
                  >
                    <Link
                      to="/customermanegment/contact_us"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Contact us
                      </p>
                    </Link>
                  </li>
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/customermanegment/complaint")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("complaint")}
                  >
                    <Link
                      to="/customermanegment/complaint"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Compalint
                      </p>
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="4"
              className={`mb-1 ${activeAccordionItem === "4" ? "active" : ""}`}
              onClick={() => handleAccordionClick("4")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  About Us
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/about_us_banner")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("about_us_banner")}
                  >
                    <Link
                      to="/masters/about_us_banner"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        About Us Banner
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/about_us_description")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() =>
                      handleInnerListItemClick("about_us_description")
                    }
                  >
                    <Link
                      to="/masters/about_us_description"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        About Us Description
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/about_us_part_two")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() =>
                      handleInnerListItemClick("about_us_part_two")
                    }
                  >
                    <Link
                      to="/masters/about_us_part_two"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        About Us Part Two
                      </p>
                    </Link>
                  </li>
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes(
                            "/masters/perspective_and_objective"
                          )
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() =>
                      handleInnerListItemClick("perspective_and_objective")
                    }
                  >
                    <Link
                      to="/masters/perspective_and_objective"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Mission & Vision
                      </p>
                    </Link>
                  </li>
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/about_us/total_security")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("total_security")}
                  >
                    <Link
                      to="/about_us/total_security"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Total Security
                      </p>
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </ul>
      </section>
    </>
  );
};

export default Sidebar;
