import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from "react-bootstrap/Offcanvas";
import $ from "jquery";
import Card from "react-bootstrap/Card";
import JoditEditor from "jodit-react";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../../common/Button";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
const axios = require("axios");
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, deleteData, IMG_URL } = useContext(Context);
  const editor = useRef(null);
  const editorCommonType = useRef(null);
  const editorUses = useRef(null);
  const editorDescription = useRef(null);
  const editorSubDescription = useRef(null);
  const id = props.show;
  const [category, setCategory] = useState([]);
  const [commonType, setCommonType] = useState([]);
  const [uses, setUses] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    category_id: "",
    description: "",
    sub_description: "",
    uses: "",
    commontype: "",
  });
  const [subCategoryImages, setSubCategoryImages] = useState([]);
  const [deleteImages, setDeleteImages] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (
      e?.name === "category_id" ||
      e?.name === "commontype_id" ||
      e?.name === "uses_id"
    ) {
      setFormData((prevData) => ({
        ...prevData,
        [e.name]: e,
      }));
    } else if (
      e?.name === "category_id" ||
      e?.name === "commontype_id" ||
      e?.name === "uses_id"
    ) {
      fetchDataForSelectedsubCategory(e.value);
    } else if (
      e?.target.name === "description" ||
      e?.target.name === "sub_description" ||
      e?.target.name === "name"
    ) {
      setFormData((prevData) => ({
        ...prevData,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const fetchDataForSelectedsubCategory = async (subcategory_id) => {
    try {
      const response = await getData(`/allsubcategoryimages/${subcategory_id}`);

      if (response?.success) {
        setSubCategoryImages(response.data.slice(0, 9));
      }
    } catch (error) {
      console.error("Error fetching category images:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".subCategoryclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".subCategoryclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        const finalData = new FormData();
        finalData.append("name", formData.name);
        finalData.append("image", formData.image);
        finalData.append("description", formData.description);
        finalData.append("sub_description", formData.sub_description);
        finalData.append("category_id", formData.category_id?.value);
        finalData.append("commontype", formData.commontype);
        finalData.append("uses", formData.uses);

        if (formData.images) {
          for (let i = 0; i < formData.images.length; i++) {
            finalData.append(`images[${i}]`, formData.images[i]);
          }
        }

        const response = await postData(
          `/masters/subCategory/${id}`,
          finalData
        );
        if (response?.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleSubDescriptionChange = (value) => {
    setFormData({ ...formData, sub_description: value });
  };

  const handleCommonTypeChange = (value) => {
    setFormData({ ...formData, commontype: value });
  };
  const handleUsesChange = (value) => {
    setFormData({ ...formData, uses: value });
  };

  const GetEditData = async () => {
    try {
      const response = await getData(`/masters/subCategory/${id}`);
      setDeleteImages(true);
      setFormData(response?.data);

      if (response?.data?.id) {
        const subCategoryImagesResponse = await fetchDataForSelectedsubCategory(
          response.data.id
        );
        setSubCategoryImages(subCategoryImagesResponse.slice(0, 9));
      }
    } catch (error) {
      console.error("Error fetching subCategory data:", error);
    }
  };

  const handleDeleteImage = async (imageId) => {
    try {
      // Assuming deleteData is a function for making HTTP DELETE request
      const response = await deleteData(
        `/masters/subCategory/deletesubcategoryimage/${imageId}`
      );

      if (response.data?.success) {
        // If the deletion is successful, update the state
        setSubCategoryImages((prevImages) =>
          prevImages.filter((image) => image.id !== imageId)
        );

        setDeleteImages(true);

        // Check the success property of the main response
      } else {
        console.error("Error deleting subcategory image:", response.data.error);
      }
    } catch (error) {
      console.error("Error deleting subcategory image:", error.message);
    }
  };

  useEffect(() => {
    window.jQuery = $;
    require("bootstrap/dist/js/bootstrap.bundle.min");
    GetEditData();
  }, []);

  useEffect(() => {
    if (props.data) {
      setFormData({
        ...formData,
        id: props.data.id,
        name: props.data.name,
        category_id: props.data.category_id,
        description: props.data.description,
        sub_description: props.data.sub_description,
        commontype: props.data.commontype,
        uses: props.data.uses,
      });
    }
  }, [props.data]);

  const renderSelectedCategoryImages = () => (
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Image</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {subCategoryImages.map((image) => (
          <tr key={image.id}>
            <td>{image.id}</td>
            <td>
              <img
                src={IMG_URL + image.imagePath}
                className="subcategory-image"
                style={{ height: "75px", width: "110px" }}
              />
            </td>
            <td>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleDeleteImage(image.id)}
              >
                Remove
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min");
    // GetEditData();
  }, [0]);

  const GetAllCategory = async () => {
    const response = await getData("/allcategory");
    const option = [];
    if (response?.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "category_id",
          label: data.name,
        });
      });
    }
    setCategory(option);
  };

  const GetAllCommonType = async () => {
    const response2 = await getData("/allcommontype");
    const option = [];
    if (response2?.success) {
      response2?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "commontype_id",
          label: data.name,
        });
      });
    }
    setCommonType(option);
  };

  const GetAllUses = async () => {
    const response3 = await getData("/alluses");
    const option = [];
    if (response3?.success) {
      response3?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "uses_id",
          label: data.name,
        });
      });
    }
    setUses(option);
  };

  useEffect(() => {
    GetAllCategory();
    GetAllCommonType();
    GetAllUses();
  }, [0]);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton />
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit Product </Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="subCategoryclass"
                          >
                            <Row>
                              <Col md={6}>
                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={5}>
                                      Sub Category Images
                                    </Form.Label>
                                  </Row>
                                  <Row>
                                    <Col sm={12}>
                                      <div className="selected-category-images">
                                        {renderSelectedCategoryImages()}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Product Category{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="category_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        placeholder="Select Product Category"
                                        value={formData?.category_id}
                                        options={category}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Product
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            // maxLength={20}
                                            value={formData?.name}
                                            onChange={handleChange}
                                            placeholder="Product "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Common Type
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <JoditEditor
                                            ref={editorCommonType}
                                            value={formData?.commontype}
                                            tabIndex={2}
                                            className="custom-jodit-editor"
                                            onChange={(value) =>
                                              handleCommonTypeChange(value)
                                            }
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Uses
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <JoditEditor
                                            ref={editorUses}
                                            value={formData?.uses}
                                            tabIndex={2}
                                            className="custom-jodit-editor"
                                            onChange={(value) =>
                                              handleUsesChange(value)
                                            }
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Image
                                    </Form.Label>

                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="image"
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                [e.target.name]:
                                                  e.target.files[0],
                                              });
                                            }}
                                            accept="image/x-png,image/gif,image/jpeg"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      images
                                    </Form.Label>

                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="images"
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                [e.target.name]: e.target.files,
                                              });
                                            }}
                                            accept="image/x-png,image/gif,image/jpeg"
                                            multiple
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Description
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <JoditEditor
                                            ref={editorDescription}
                                            value={formData?.description}
                                            tabIndex={2}
                                            className="custom-jodit-editor"
                                            onChange={(value) =>
                                              handleDescriptionChange(value)
                                            }
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Sub Description
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <JoditEditor
                                            ref={editorSubDescription}
                                            value={formData?.sub_description}
                                            tabIndex={2}
                                            className="custom-jodit-editor"
                                            onChange={(value) =>
                                              handleSubDescriptionChange(value)
                                            }
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
