import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { Col, Container, Row, Form, InputGroup } from "react-bootstrap";
library.add(fas);
const EditOffCanvance = (props) => {
  const editor = useRef(null);
  const { postData, getData, IMG_URL } = useContext(Context);
  const id = props.show;
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    description: "",
    image: null,
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState();
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const handleChange = (e) => {
    if (e.target.name === "type") {
      // If the type changes, reset the image and its preview
      setFormData({ ...formData, type: e.target.value, image: null });
      setImagePreview(null);
    } else if (e.target.name === "image") {
      const file = e.target.files[0];

      // Update formData with the selected image
      setFormData({ ...formData, image: file });

      if (file.type.startsWith("image")) {
        // Only for image files, handle image preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setImagePreview(null); // Reset image preview for non-image files
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("type", formData?.type);
        formDataToSend.append("name", formData?.name);
        formDataToSend.append("description", formData?.description);
        formDataToSend.append("image", formData?.image);

        const response = await postData(`/masters/banner/${id}`, formDataToSend);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
        else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
        await setErrors();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData?.type) {
      errors.type = "Type is required";
    }
    if (!formData?.name) {
      errors.name = "Title is required";
    }

    if (!formData?.description) {
      errors.description = "Description is required";
    }

    if (!formData?.image) {
      errors.image = "Image is required";
    }
    // else {
    //   // Validate file type based on selected "type"
    //   if (formData.type === "image" && !formData.image.type?.startsWith("image")) {
    //     errors.image = "Please select a valid image file";
    //   } else if (formData.type === "video" && !formData.image.type?.startsWith("video")) {
    //     errors.image = "Please select a valid video file";
    //   }
    // }
    return errors;
  };


  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/banner/${id}`);
    setFormData(response?.data);
  };
  useEffect(() => {
    GetEditData();
  }, []);
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit banner</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                  <Card.Body>
                    <Card.Title>Edit Banner</Card.Title>
                    <hr />
                    <Container>
                      <Row>
                        <Col md={8}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="bannerclass"
                            encType="multipart/form-data"
                          >
                            <Row>

                              <Col sm={6}>
                                <Form.Group>
                                  <InputGroup>
                                    {/* Use the Select component for the type dropdown */}
                                    <Select
                                      name="type"
                                      className="input-mandatory"
                                      value={formData?.type ? { label: formData?.type, value: formData?.type } : null}
                                      onChange={(selectedOption) => {
                                        handleChange({ target: { name: "type", value: selectedOption?.value } });
                                      }}
                                      options={[
                                        { value: "image", label: "Image" },
                                        { value: "video", label: "Video" },
                                      ]}
                                    />
                                  </InputGroup>
                                  {errors?.type && (
                                    <span style={errorStyle}>{errors?.type}</span>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md={12}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Name
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData?.name}
                                            onChange={handleChange}
                                            placeholder="Banner"
                                            className="input-mandatory"
                                            id="inputEmail3"
                                          />
                                          <span className="error-message"></span>
                                        </InputGroup>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>


                            <Row>
                              <Col md={12}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Description
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>

                                          <JoditEditor
                                            ref={editor}
                                            value={formData?.description}
                                            tabIndex={2}
                                            className="custom-jodit-editor"
                                            //onBlur={newContent=>setContent(newContent)}
                                            onChange={handleDescriptionChange}
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>

                            <Row className="justify-content-center mt-3">
                              <Form.Label column sm={3}>
                                Video/Image
                              </Form.Label>
                              <Col sm={6}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="file"
                                      name="image"
                                      onChange={handleChange}
                                      accept="image/*,video/*"
                                      className="form-control"
                                    />
                                  </InputGroup>
                                  {errors?.image && (
                                    <span style={errorStyle}>
                                      {errors?.image}
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>

                              <Col md={4} className="d-flex align-items-center">
                                {imagePreview
                                  ? formData?.image &&
                                  (formData.image?.type?.startsWith("image") ? (
                                    <img
                                      src={imagePreview}
                                      alt="Image Preview"
                                      className="table-image"
                                      style={{
                                        height: "100px",
                                        width: "100%",
                                        backgroundSize: "cover",
                                      }}
                                    />
                                  ) : (
                                    <video
                                      src={imagePreview}
                                      alt="Video Preview"
                                      className="table-video"
                                      style={{
                                        height: "100px",
                                        width: "100%",
                                        objectFit: "cover",
                                      }}
                                      controls
                                    />
                                  ))
                                  : formData?.image &&
                                  (formData.image?.type?.startsWith("image") ? (
                                    <img
                                      src={IMG_URL + formData.image}
                                      alt={formData?.image || "Image"}
                                      className="table-image"
                                      style={{
                                        height: "100px",
                                        width: "100%",
                                        backgroundSize: "cover",
                                      }}
                                    />
                                  ) : (
                                    <video
                                      src={IMG_URL + formData.image}
                                      alt={formData?.image || "Video"}
                                      className="table-video"
                                      style={{
                                        height: "100px",
                                        width: "100%",
                                        objectFit: "cover",
                                      }}
                                      controls
                                    />
                                  ))}
                              </Col>
                            </Row>
                          </Form>
                          <Row className="mt-5 pb-3">
                            <div className="d-flex justify-content-center">
                              <Link>
                                <CancelButton
                                  name={"cancel"}
                                  handleClose={props.handleClose}
                                />
                              </Link>
                              <SaveButton
                                name={"save"}
                                handleSubmit={handleSubmit}
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};
export default EditOffCanvance;
