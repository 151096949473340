import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../../common/Button";
import JoditEditor from "jodit-react";
import { Col, Container, Row, Form, InputGroup } from "react-bootstrap";
library.add(fas);
const EditOffCanvance = (props) => {
  const editor = useRef(null);
  const { postData, getData, IMG_URL } = useContext(Context);
  const id = props.show;
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    // image: null,
  });

  // const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState();
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "image") {
      setFormData({ ...formData, image: e.target.files[0] });

      const reader = new FileReader();
      // reader.onloadend = () => {
      //   setImagePreview(reader.result);
      // };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const [content, setContent] = useState("");

  const handleDescriptionChange = (value) => {
    const trimmedValue = value.slice(0, 250);
    setFormData({ ...formData, description: trimmedValue });
    setContent(trimmedValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("description", formData.description);
        // formDataToSend.append("image", formData.image);
        const response = await postData(
          `/masters/perspective_and_objective/${id}`,
          formDataToSend
        );
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
        await setErrors();
      } catch (error) {
        console.log(error);
      }
    }
  };
  const nameOptions = [
    { value: "Mission", label: "Mission" },
    { value: "Vision", label: "Vision" },
  ];

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Name is required";
    } else if (!regexAlfa.test(formData.name?.trim())) {
      errors.name = "Enter a valid data";
    }
    if (!formData.description) {
      errors.description = "Description is required";
    }


    return errors;
  };
  const GetEditData = async () => {
    const response = await getData(`/masters/perspective_and_objective/${id}`);
    setFormData(response?.data);
  };
  useEffect(() => {
    GetEditData();
  }, []);
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit banner</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container fluid>
            <Row>
              {/* Right side - Form */}
              <Col md={12}>
                <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                  <Card.Body>
                    <Card.Title>Edit Mission And Vision</Card.Title>
                    <hr />
                    <Container>
                      <Row>
                        {/* Left side - Image */}
                        {/* <Col md={4}>
                          {imagePreview ? (
                            <img
                              src={imagePreview}
                              alt="Image Preview"
                              className="table-image"
                              style={{ height: "300px", width: "440px" }}
                            />
                          ) : (
                            formData?.image && (
                              <img
                                src={IMG_URL + formData.image}
                                alt={formData?.image || "Image"}
                                className="table-image"
                                style={{ height: "300px", width: "440px" }}
                              />
                            )
                          )}
                        </Col> */}
                        {/* Editing fields */}

                        <Row>
                          {/* <Col md={10}>
                            <div className="main-form-section mt-5">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Name
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <InputGroup>
                                      <Select
                                        name="name"
                                        className="input-mandatory"
                                        onChange={(selectedOption) =>
                                          setFormData({ ...formData, name: selectedOption.value })
                                        }
                                        value={
                                          nameOptions.find(
                                            (option) => option.value === formData?.name
                                          ) || null
                                        }
                                        options={nameOptions}
                                      />
                                    </InputGroup>
                                    {errors?.name && (
                                      <span style={errorStyle}>{errors?.name}</span>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col> */}
                          <Col md={10}>
                            <div className="main-form-section mt-5">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Description
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <InputGroup>
                                      {/* <Form.Control
                                        type="text"
                                        name="details"
                                        value={formData?.details}
                                        onChange={handleChange}
                                        placeholder="client"
                                        className="input-mandatory"
                                        id="inputEmail3"
                                      /> */}
                                      <JoditEditor
                                        ref={editor}
                                        value={formData?.description}
                                        tabIndex={2}
                                        className="custom-jodit-editor"
                                        onBlur={newContent => setContent(newContent)}
                                        onChange={handleDescriptionChange}
                                      />
                                      <span className="error-message"></span>
                                    </InputGroup>
                                    {errors?.description && (
                                      <span style={errorStyle}>
                                        {errors?.description}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          {/* <Col md={6}>
                            <div className="main-form-section mt-5">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Image
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="file"
                                        name="image"
                                        onChange={handleChange}
                                      />
                                    </InputGroup>
                                    {errors?.image && (
                                      <span style={errorStyle}>
                                        {errors?.image}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Col>
                           
                                <Col md={6}>
                                  {imagePreview ? (
                                    <img
                                      src={imagePreview}
                                      alt="Image Preview"
                                      className="table-image"
                                      style={{
                                        height: "100px",
                                        width: "100px",
                                        marginLeft: "200px",
                                      }}
                                    />
                                  ) : (
                                    formData?.image && (
                                      <img
                                        src={IMG_URL + formData.image}
                                        alt={formData?.image || "Image"}
                                        className="table-image"
                                        style={{
                                          height: "100px",
                                          width: "100px",
                                          marginLeft: "200px",
                                          marginTop: "2px",
                                        }}
                                      />
                                    )
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Col> */}
                        </Row>

                        <Row className="mt-5 pb-3">
                          <div className="d-flex justify-content-center">
                            <Link>
                              <CancelButton
                                name={"cancel"}
                                handleClose={props.handleClose}
                              />
                            </Link>
                            <SaveButton
                              name={"save"}
                              handleSubmit={handleSubmit}
                            />
                          </div>
                        </Row>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};
export default EditOffCanvance;
