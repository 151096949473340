import React, { useContext, useRef } from "react";
import { useState } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import JoditEditor from "jodit-react";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData } = useContext(Context);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    // image: null,
  });
  // const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState();
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e.target.type === "file") {
      const selectedImage = e.target.files[0];

      const reader = new FileReader();

      reader.readAsDataURL(selectedImage);

      setFormData({ ...formData, image: selectedImage });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleDescriptionChange = (value) => {
    const trimmedValue = value.slice(0, 250);
    setFormData({ ...formData, description: trimmedValue });
    setContent(trimmedValue);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("description", formData.description);
        // formDataToSend.append("image", formData.image);
        const response = await postData(
          `/masters/perspective_and_objective`,
          formDataToSend
        );
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
        await setErrors();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Name is required";
    } else if (!regexAlfa.test(formData.name?.trim())) {
      errors.name = "Enter a valid data";
    }
    if (!formData.description) {
      errors.description = "Description is required";
    }


    return errors;
  };
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };
  const nameOptions = [
    { value: "Mission", label: "Mission" },
    { value: "Vision", label: "Vision" },
  ];
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton />
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded ">
            <Card.Body>
              <Card.Title>Add Mission And Vision</Card.Title>
              <hr />
              <Container>
                <Row>
                  {/* Right side - Form */}
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className=".totalsecurityclass"
                            encType="multipart/form-data"
                          >
                            <Row>
                              <Row>
                                <Col md={10}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Name
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Select
                                              name="name"
                                              className="input-mandatory"
                                              onChange={(selectedOption) =>
                                                setFormData({ ...formData, name: selectedOption.value })
                                              }
                                              value={nameOptions.find(
                                                (option) => option.value === formData.name
                                              )}
                                              options={nameOptions}
                                            />
                                          </InputGroup>
                                          {errors?.name && (
                                            <span style={errorStyle}>{errors?.name}</span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col md={10}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Description
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            {/* <Form.Control
                                              type="text"
                                              name="details"
                                              value={formData.details}
                                              onChange={handleChange}
                                              placeholder="details"
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            /> */}
                                            <JoditEditor
                                              ref={editor}
                                              value={formData.description}
                                              tabIndex={2}
                                              maxLength={200} // Set the maximum length to 200 characters
                                              className="custom-jodit-editor"
                                              onBlur={(newContent) => setContent(newContent)}
                                              onChange={handleDescriptionChange}
                                            />
                                          </InputGroup>
                                          {errors?.description && (
                                            <span style={errorStyle}>
                                              {errors?.description}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                {/* <Col md={6}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Image
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="file"
                                              name="image"
                                              onChange={handleChange}
                                              accept="image/*"
                                            />
                                          </InputGroup>
                                          {errors?.image && (
                                            <span style={errorStyle}>
                                              {errors?.image}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                      <Col md={4}>
                                        {imagePreview ? (
                                          <img
                                            src={imagePreview}
                                            alt="Image Preview"
                                            className="table-image"
                                            style={{
                                              height: "100px",
                                              width: "100px",
                                              marginLeft: "100px",
                                            }}
                                          />
                                        ) : (
                                          <p>No image selected</p>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col> */}
                              </Row>

                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
