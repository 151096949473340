import React, { useContext, useState } from "react";
import "./Header.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";

import Cookies from "js-cookie";
import { Context } from "../../../utils/context";
library.add(fas);

function Header(props) {
  const { setUserData, setUsertype, setSignin, usertype } = useContext(Context);

  const navigate = useNavigate();

  const LogOut = async () => {
    Cookies.remove("it_pulse_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };

  return (
    <>
      <section className="header">
        <Navbar expand="md" className="bg-body-tertiary">
          <Navbar.Brand>
            <div className="header-text">
              <h1>{props.title}</h1>
              <p>Home</p>
              <p>
                <FontAwesomeIcon className="users" icon="fa-solid fa-circle" />
                {props.title}
              </p>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <div className="header-icons">
                {/* <div className="icon">
                  <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                </div>
                <div className="icon">
                  <FontAwesomeIcon icon="fa-solid fa-note-sticky" />
                </div>
                <div className="icon">
                  <FontAwesomeIcon icon="fa-solid fa-clock" />
                </div>
                <div className="icon">
                  <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                </div>
                <div className="icon">
                  <FontAwesomeIcon icon="fa-solid fa-bell" />
                </div> */}
                <div className="icon deskviewlogout" onClick={LogOut}>
                  <FontAwesomeIcon
                    className="users"
                    icon="fa-solid fa-power-off"
                  />
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>

          <div className="icon mobileviewlogout" onClick={LogOut}>
                  <FontAwesomeIcon
                    className="users"
                    icon="fa-solid fa-power-off"
                  />
                </div>
        </Navbar>
      </section>
    </>
  );
}

export default Header;
