import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import JoditEditor from "jodit-react";
import { Col, Container, Row, Form, InputGroup } from "react-bootstrap";
library.add(fas);
const EditOffCanvance = (props) => {
  const editor = useRef(null);
  const { postData, getData, IMG_URL } = useContext(Context);
  const id = props.show;
  const [formData, setFormData] = useState({
    name: "",
    details: "",
    image: null,
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState();
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e.target.type === "file") {
      const selectedImage = e.target.files[0];

      // Check if a file is selected
      if (selectedImage) {
        // Generate image preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(selectedImage);

        // Update formData with the selected image
        setFormData({ ...formData, image: selectedImage });
      } else {
        // User canceled image selection, clear the image preview and formData
        setImagePreview(null);
        setFormData({ ...formData, image: null });
      }
    } else {
      // Handle text input
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, details: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("details", formData.details);
        formDataToSend.append("image", formData.image);
        const response = await postData(
          `/masters/product_spotlight/${id}`,
          formDataToSend
        );
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
        await setErrors();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Name is required";
    }

    if (!formData.details) {
      errors.details = "Details is required";
    }

    if (!formData.image) {
      errors.image = "Image Is required";
    }

    return errors;
  };
  const GetEditData = async () => {
    const response = await getData(`/masters/product_spotlight/${id}`);
    setFormData(response?.data);
  };
  useEffect(() => {
    GetEditData();
  }, []);
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit banner</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container fluid>
            <Row>
              {/* Right side - Form */}
              <Col md={12}>
                <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                  <Card.Body>
                    <Card.Title>Edit Product Spotlight</Card.Title>
                    <hr />
                    <Container>
                      <Row>
                        {/* Left side - Image */}
                        {/* <Col md={4}>
                          {imagePreview ? (
                            <img
                              src={imagePreview}
                              alt="Image Preview"
                              className="table-image"
                              style={{ height: "300px", width: "440px" }}
                            />
                          ) : (
                            formData?.image && (
                              <img
                                src={IMG_URL + formData.image}
                                alt={formData?.image || "Image"}
                                className="table-image"
                                style={{ height: "300px", width: "440px" }}
                              />
                            )
                          )}
                        </Col> */}
                        {/* Editing fields */}

                        <Row>
                          <Col md={6}>
                            <div className="main-form-section mt-5">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Name
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        name="name"
                                        value={formData?.name}
                                        onChange={handleChange}
                                        placeholder="Name"
                                        className="input-mandatory"
                                        id="inputEmail3"
                                      />
                                      <span className="error-message"></span>
                                    </InputGroup>
                                    {errors?.name && (
                                      <span style={errorStyle}>
                                        {errors?.name}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="main-form-section mt-5">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Details
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <InputGroup>
                                      {/* <Form.Control
                                        type="text"
                                        name="details"
                                        value={formData?.details}
                                        onChange={handleChange}
                                        placeholder="client"
                                        className="input-mandatory"
                                        id="inputEmail3"
                                      /> */}
                                      <JoditEditor
                                        ref={editor}
                                        value={formData?.details}
                                        tabIndex={2}
                                        className="custom-jodit-editor"
                                        //onBlur={newContent=>setContent(newContent)}
                                        onChange={handleDescriptionChange}
                                      />
                                      <span className="error-message"></span>
                                    </InputGroup>
                                    {errors?.details && (
                                      <span style={errorStyle}>
                                        {errors?.details}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="main-form-section mt-5">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Image
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="file"
                                        name="image"
                                        onChange={handleChange}
                                        accept=".jpg, .jpeg, .png"
                                      />
                                    </InputGroup>
                                    {errors?.image && (
                                      <span style={errorStyle}>
                                        {errors?.image}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Col>
                                {/* Left side - Image */}
                                <Col md={6}>
                                  {imagePreview ? (
                                    <img
                                      src={imagePreview}
                                      alt="Image Preview"
                                      className="table-image"
                                      style={{
                                        height: "100px",
                                        width: "100px",
                                        marginLeft: "200px",
                                      }}
                                    />
                                  ) : (
                                    formData?.image && (
                                      <img
                                        src={IMG_URL + formData.image}
                                        alt={formData?.image || "Image"}
                                        className="table-image"
                                        style={{
                                          height: "100px",
                                          width: "100px",
                                          marginLeft: "200px",
                                          marginTop: "2px",
                                        }}
                                      />
                                    )
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-5 pb-3">
                          <div className="d-flex justify-content-center">
                            <Link>
                              <CancelButton
                                name={"cancel"}
                                handleClose={props.handleClose}
                              />
                            </Link>
                            <SaveButton
                              name={"save"}
                              handleSubmit={handleSubmit}
                            />
                          </div>
                        </Row>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};
export default EditOffCanvance;
