import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
// import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Col, Container, Row, Form, InputGroup } from "react-bootstrap";
library.add(fas);
const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL } = useContext(Context);
  const id = props.show;
  const [formData, setFormData] = useState({
    name: "",
    image: null,
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const handleChange = (e) => {
    if (e.target.type === "file") {
      const selectedImage = e.target.files[0];

      // Check if a file is selected
      if (selectedImage) {
        // Generate image preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(selectedImage);

        // Update formData with the selected image
        setFormData({ ...formData, image: selectedImage });
      } else {
        // User canceled image selection, clear the image preview and formData
        setImagePreview(null);
        setFormData({ ...formData, image: null });
      }
    } else {
      // Handle text input
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = Validation.FormValidation({
      classname: ".ourclientclass",
    });
    console.log(validationErrors);
    if (validationErrors) {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("image", formData.image);
        const response = await postData(
          `/masters/ourclients/${id}`,
          formDataToSend
        );
        setShowModal({ code: response?.code, message: response?.message });

        setTimeout(() => {
          setShowModal(0);
          props.handleClose();
        }, 1000);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };
  const GetEditData = async () => {
    const response = await getData(`/masters/ourclients/${id}`);
    setFormData(response?.data);
  };
  useEffect(() => {
    GetEditData();
  }, []);
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Container fluid>
            <Row>
              {/* Right side - Form */}
              <Col md={12}>
                <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                  <Card.Body>
                    <Card.Title>Edit Our Client</Card.Title>
                    <hr />
                    <Container>
                      <Row>
                        {/* Editing fields */}
                        <Col md={8}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="ourclientclass"
                            encType="multipart/form-data"
                          >
                            <Row>
                              <Col md={12}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Name
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData?.name}
                                            onChange={handleChange}
                                            placeholder="Name"
                                            className="input-mandatory"
                                            id="inputEmail3"
                                          />

                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                            {/* New field for uploading an image */}
                            <Row className="justify-content-center mt-3">
                              <Form.Label column sm={3}>
                                Image
                              </Form.Label>
                              <Col sm={6}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="file"
                                      name="image"
                                      onChange={handleChange}
                                    />
                                  </InputGroup>
                                  <span className="error-message"></span>
                                </Form.Group>
                              </Col>
                              {/* Left side - Image */}
                              <Col md={4}>
                                {imagePreview ? (
                                  <img
                                    src={imagePreview}
                                    alt="Image Preview"
                                    className="table-image"
                                    style={{
                                      height: "100px",
                                      width: "150px",
                                      margin: "4px",
                                      marginLeft: "150px",
                                      backgroundSize: "fixed",
                                    }}
                                  />
                                ) : (
                                  formData?.image && (
                                    <img
                                      src={IMG_URL + formData.image}
                                      alt={formData?.image || "Image"}
                                      className="table-image"
                                      style={{
                                        height: "100px",
                                        width: "150px",
                                        marginTop: "4px",
                                        marginLeft: "150px",
                                        backgroundSize: "fixed",
                                      }}
                                    />
                                  )
                                )}
                              </Col>
                            </Row>

                          </Form>
                          <Row className="mt-5 pb-3">
                            <div className="d-flex justify-content-center">
                              <Link>
                                <CancelButton
                                  name={"cancel"}
                                  handleClose={props.handleClose}
                                />
                              </Link>
                              <SaveButton
                                name={"save"}
                                handleSubmit={handleSubmit}
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};
export default EditOffCanvance;
