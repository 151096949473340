import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Admin/Login/Login";

import Dashboard from "./Components/Admin/DashBoard/DashBoard";
import Sidebar from "./Components/Admin/Sidebar/Sidebar";
import Header from "./Components/Admin/Header/Header";
import AdvanceDashboard from "./Components/Admin/DashBoard/AdvanceDashboard/AdvanceDashboard";
import PrivateDashboard from "./Components/Admin/DashBoard/PrivateDashboard/PrivateDashboard";
import Order from "./Components/Admin/DashBoard/Order/Order";

// Masters

// import PrimarySkills from "./Components/Admin/Masters/PrimarySkills/Tables";
// import SecondarySkills from "./Components/Admin/Masters/SecondarySkills/Tables";

// Talent Pool
//Customer management
import QueryInquiry from "./Components/Admin/CustomerManagement/QueryInquiry/Tables";
import Contact_us from "./Components/Admin/CustomerManagement/Contact_us/Tables";
import Complaint from "./Components/Admin/CustomerManagement/Complaint/Tables";
// Recruiters

import Country from "./Components/Admin/Masters/Country/Tables";
import State from "./Components/Admin/Masters/State/Tables";
import City from "./Components/Admin/Masters/City/Tables";
import Banner from "./Components/Admin/Masters/Banner/Tables";
import Application from "./Components/Admin/Masters/Application/Tables";
import OurClient from "./Components/Admin/Masters/OurClient/Table";
import Solutions from "./Components/Admin/Masters/Solutions/Tables";
import Category from "./Components/Admin/Masters/Category/Tables";
import SubCategory from "./Components/Admin/Masters/SubCategory/Tables";

import TotalSecurity from "./Components/Admin/About_us/TotalSecurity/Table";

import ProductSpotlight from "./Components/Admin/Masters/ProductSpotlight/Table";

import OurBrand from "./Components/Admin/Masters/OurBrand/Table";

import PerspectiveAndObjective from "./Components/Admin/Masters/PerspectiveAndObjective/Table";

import OurStrength from "./Components/Admin/Masters/OurStrength/Table";
import Support from "./Components/Admin/Masters/Support/Table";
import NewsEvents from "./Components/Admin/Masters/NewsEvents/Tables";
import DirectorProfile from "./Components/Admin/Masters/DirectorProfile/Table";
import HomeLower from "./Components/Admin/Masters/HomeLower/Table";
import AboutUsBanner from "./Components/Admin/Masters/AboutUsBanner/Table";
import AboutUsPartTwo from "./Components/Admin/Masters/AboutUsPartTwo/Table";
import HomePartTwo from "./Components/Admin/Masters/HomePartTwo/Table";
import AboutUsDescription from "./Components/Admin/Masters/AboutUsDescription/Table";
import CommonType from "./Components/Admin/Masters/CommonType/Table";
import Uses from "./Components/Admin/Masters/Uses/Table";
import HappyCustomers from "./Components/Admin/Masters/HappyCustomers/Table";

const App = () => {
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });
  return (
    <div className="main d-flex">
      {headerText === "/" || headerText === "/lead-dashboard" ? (
        <></>
      ) : (
        <Sidebar />
      )}

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Header" element={<Header />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/advanceDashboard" element={<AdvanceDashboard />} />

        {/* costomer Management Start */}

        {/* QueryInquiry Start */}
        <Route
          path="/customermanegment/queryinquiry"
          element={<QueryInquiry />}
        />

        {/* Contact us Start */}
        <Route path="/customermanegment/contact_us" element={<Contact_us />} />

        {/* Complaint Start */}
        <Route path="/customermanegment/complaint" element={<Complaint />} />

        {/* Masters Start */}

        {/*Country */}
        <Route path="/masters/country" element={<Country />} />

        {/*State */}
        <Route path="/masters/state" element={<State />} />

        {/*City */}
        <Route path="/masters/city" element={<City />} />

        {/*banner */}
        <Route path="/masters/banner" element={<Banner />} />

        {/*application */}
        <Route path="/masters/application" element={<Application />} />

        {/*ourclient */}
        <Route path="/masters/ourclients" element={<OurClient />} />

        {/*Solutions */}
        <Route path="/masters/solution" element={<Solutions />} />

        {/*Category */}
        <Route path="/masters/category" element={<Category />} />

        {/*Sub Category */}
        <Route path="/masters/subCategory" element={<SubCategory />} />

        <Route path="/about_us/total_security" element={<TotalSecurity />} />

        <Route
          path="/masters/product_spotlight"
          element={<ProductSpotlight />}
        />

        <Route path="/masters/our_brand" element={<OurBrand />} />

        <Route
          path="/masters/perspective_and_objective"
          element={<PerspectiveAndObjective />}
        />

        <Route path="/masters/ourstrength" element={<OurStrength />} />

        <Route path="/masters/support" element={<Support />} />
        {/*Sub Category */}
        <Route path="/masters/newsevents" element={<NewsEvents />} />

        <Route path="/masters/director_profile" element={<DirectorProfile />} />
        <Route path="/masters/home_lower" element={<HomeLower />} />
        <Route path="/masters/about_us_banner" element={<AboutUsBanner />} />
        <Route path="/masters/about_us_part_two" element={<AboutUsPartTwo />} />
        <Route path="/masters/home_part_two" element={<HomePartTwo />} />
        <Route
          path="/masters/about_us_description"
          element={<AboutUsDescription />}
        />
        <Route path="/masters/common_type" element={<CommonType />} />
        <Route path="/masters/uses" element={<Uses />} />
        <Route path="/masters/happy_customers" element={<HappyCustomers />} />
      </Routes>
    </div>
  );
};

export default App;
