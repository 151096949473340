import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AdvanceDashboard.css";
import Header from "../../Header/Header";
import Piechart from "../Piechart/Piechart";
import Barchart from "../Barchart/Barchart";
import LineChart from "../LineChart/LineChart";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../../utils/context";
library.add(fas);
function AdvanceDashboard() {
  const { getData, IMG_URL } = useContext(Context);

  const [complaint, setComplaint] = useState([]);
  const [query, setQuery] = useState([]);
  const [contact, setContact] = useState([]);


  const getComplaint = async () => {
    try {
      const response = await getData(`/customermanegment/complaint`);
      setComplaint(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getQuery = async () => {
    try {
      const response = await getData(`/customermanegment/queryinquiry`);
      setQuery(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getAllContact = async () => {
    try {
      const response = await getData(`/customermanegment/contact_us`);
      setContact(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    getComplaint();
    getQuery();
    getAllContact();
  }, [0]);






  const [startDate, setStartDate] = useState(new Date());

  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  const toggleDatePicker = () => {
    setDatePickerOpen(!isDatePickerOpen);
  };
  return (
    <div className="main-advancedashboard">
      <Header title={"Dashboard"} link={"/"} />
      <section className="AdvanceDashboard">
        <div className="AdvanceDashboardTabs">
          <div className="row justify-content-end me-0 ms-0">
            <div className="col-xxl-12 col-12 p-0">
              <div className="badhboard-header sticky-top bg-white">
                <ul
                  className="nav nav-tabs bg-white "
                  id="myTab"
                  role="tablist"
                >
                  <div className="vr"></div>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="Overview-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Overview-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Overview-tab-pane"
                      aria-selected="true"
                    >
                      Overview
                    </button>
                  </li>
                  <div className="vr"></div>
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Project-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Project-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Project-tab-pane"
                      aria-selected="false"
                    >
                      Project
                    </button>
                  </li>
                  <div className="vr"></div>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Client-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Client-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Client-tab-pane"
                      aria-selected="false"
                    >
                      Client
                    </button>
                  </li>
                  <div className="vr"></div>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="HR-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#HR-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="HR-tab-pane"
                      aria-selected="false"
                    >
                      HR
                    </button>
                  </li>
                  <div className="vr"></div>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Ticket-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Ticket-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Ticket-tab-pane"
                      aria-selected="false"
                    >
                      Ticket
                    </button>
                  </li>
                  <div className="vr"></div>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Finance-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Finance-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Finance-tab-pane"
                      aria-selected="false"
                    >
                      Finance
                    </button>
                  </li> */}
                  <div className="vr"></div>
                </ul>
                {/* <div className="setting">
                  <div className="vr me-2"></div>
                  <div className="calender ">
                    <div>
                      <FontAwesomeIcon className="users" icon="fa-solid fa-calendar-days" onClick={toggleDatePicker} />{" "}
                      {isDatePickerOpen && (
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          onClose={() => setDatePickerOpen(false)}
                        />
                      )}
                    </div>
                  </div>
                  <div className="vr me-2"></div>
                  <div>
                    <FontAwesomeIcon className="users" icon="fa-solid fa-gear" />
                  </div>
                </div> */}
              </div>

              <div className="tab-content" id="myTabContent">
                {/* overview tab start */}
                <div
                  className="tab-pane fade show active"
                  id="Overview-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Overview-tab"
                  tabindex="0"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">


                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Total Clients</h5>

                            <div className="number-holder">
                              <p className="number">
                                <span id="">{contact.totalEntries}</span>
                              </p>
                            </div>

                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-users" />
                          </div>
                        </div>
                      </div>


                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Total Complaint</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">{complaint.totalEntries}</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-user" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Total Query</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">{query.totalEntries}</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-layer-group" />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Due Invoices</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">10</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-file-invoice" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Hours Logged</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">76 Hrs</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">
                              Pending Tasks{" "}
                            
                            </h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">20</span>
                              </p>
                            </div>
                          </div>
                          <div className="users ">
                            <FontAwesomeIcon icon="fa-solid fa-list-check" />
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Today Attendance</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0/14</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-calendar-check" />
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Unresolved Tickets</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">5</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-ticket" />
                          </div>
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="row">
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Income{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Timesheet{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Pending Leaves{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-calendar" />
                              <div className="no-data">
                                <p>- Not record Found -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Open Tickets{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-ticket" />
                              <div className="no-data">
                                <p>- Not record found -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Pending Tasks{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <table className="table" id="example">
                              <tbody>
                                <tr className="to-do">
                                  <td className="text-holder">
                                    <div>
                                      <h5>
                                        <a>egvdfbedrbe</a>
                                      </h5>
                                    </div>
                                    <div>
                                      <span>Face detector</span>
                                    </div>
                                  </td>
                                  <td className="img-holder">
                                    <div>
                                      <img
                                        src={process.env.PUBLIC_URL + ""}
                                        className="slider-img"
                                        alt="..."
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={process.env.PUBLIC_URL + ""}
                                        className="slider-img"
                                        alt="..."
                                      />
                                    </div>
                                  </td>
                                  <td className="date-holder">
                                    <div>
                                      <p> 18-10-2023 </p>
                                    </div>
                                  </td>
                                  <td className="title-holder">
                                    <div>
                                      <p>
                                        <FontAwesomeIcon className="users" icon="fa-solid fa-circle" />{" "}
                                        To Do
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Pending FollowUp{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-users" />
                              <div className="no-data">
                                <p>- Not record found -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Project Activity Timeline{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-list-check" />
                              <div className="no-data">
                                <p>- Not record found -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              User Activity Timeline{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-users" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* overview tab end */}

                {/* project tab start */}
                <div
                  className="tab-pane fade "
                  id="Project-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Project-tab"
                  tabindex="0"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Total Projects</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">1</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon className="users" icon="fa-solid fa-layer-group" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Overdue Projects</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon className="users" icon="fa-solid fa-layer-group" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Hours Logged</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">2m</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Status Wise Projects</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>Pending Milestone</h4>
                          </div>
                          <div className="card-body">
                            <table
                              className="table border-0 pb-3 admin-dash-table table-hover"
                              id="example"
                            >
                              <thead className="heading">
                                <tr className="to-do">
                                  <th className="">#</th>
                                  <th>Milestone Title</th>
                                  <th>Milestone Cost</th>
                                  <th>Project</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* <tr> */}
                                {/* <td colspan="5" className=""> */}
                                <div className="card-body-data">
                                  <FontAwesomeIcon className="users" icon="fa-solid fa-list" />
                                  <div className="no-data">
                                    <p>- Not enough data -</p>
                                  </div>
                                </div>
                                {/* </td> */}
                                {/* </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* project tab end */}

                {/* client tab start */}
                <div
                  className="tab-pane fade "
                  id="Client-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Client-tab"
                  tabindex="0"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">
                      <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Total Clients</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-users" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Total Leads</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">2</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon className="users" icon="fa-solid fa-users" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Lead Conversions</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon className="users" icon="fa-solid fa-users" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Contracts Generated</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon className="users" icon="fa-solid fa-file-contract" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Contracts Signed</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon className="users" icon="fa-solid fa-file-signature" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Client Wise Earnings{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>Client Wise Timelogs</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Leads Count by Status</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>Leads Count by Source</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Latest Clients</h4>
                          </div>
                          <div className="card-body">
                            <table
                              className="table border-0 pb-3 admin-dash-table table-hover"
                              id="example"
                            >
                              <thead className="heading">
                                <tr className="to-do">
                                  <th className="">#</th>
                                  <th>Milestone Title</th>
                                  <th>Milestone Cost</th>
                                  <th>Project</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* <tr> */}
                                {/* <td colspan="5" className=""> */}
                                <div className="card-body-data">
                                  <FontAwesomeIcon className="users" icon="fa-solid fa-list" />
                                  <div className="no-data">
                                    <p>- Not enough data -</p>
                                  </div>
                                </div>
                                {/* </td> */}
                                {/* </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Recent Login Activities</h4>
                          </div>
                          <div className="card-body">
                            <table
                              className="table border-0 pb-3 admin-dash-table table-hover"
                              id="example"
                            >
                              <thead className="heading">
                                <tr className="to-do">
                                  <th className="">#</th>
                                  <th>Milestone Title</th>
                                  <th>Milestone Cost</th>
                                  <th>Project</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* <tr> */}
                                {/* <td colspan="5" className=""> */}
                                <div className="card-body-data">
                                  <FontAwesomeIcon className="users" icon="fa-solid fa-list" />
                                  <div className="no-data">
                                    <p>- Not enough data -</p>
                                  </div>
                                </div>
                                {/* </td> */}
                                {/* </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* client tab end */}

                {/* HR tab start */}
                <div
                  className="tab-pane fade "
                  id="HR-tab-pane"
                  role="tabpanel"
                  aria-labelledby="HR-tab"
                  tabindex="0"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">
                              Leaves Approved
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-plane-departure" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Employees</h5>
                            <div className="number-holder">
                              <p className="number">
                                <div>
                                  <span id="">14</span>
                                </div>
                                <div>
                                  <p>
                                    Total Employees{" "}
                                    <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                                  </p>
                                </div>
                              </p>
                              <p className="number">
                                <div>
                                  <span id="">0</span>
                                </div>
                                <div>
                                  <p>
                                    Total Employees{" "}
                                    <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                                  </p>
                                </div>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-user" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">
                              Employees Exist{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon className="users" icon="fa-solid fa-right-from-bracket" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Total attendance</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">1/14</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon className="users" icon="fa-solid fa-calendar-check" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Average Attendance</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">7.14%</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon className="users" icon="fa-solid fa-fingerprint" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Department Wise Employee{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            {/* <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div> */}
                            <Piechart />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Designation Wise Employee{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            {/* <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div> */}
                            <Piechart />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Gender Wise Employee{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            {/* <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div> */}
                            <Piechart />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Role Wise Employee{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            {/* <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div> */}
                            <Piechart />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Headcount{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            {/* <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div> */}
                            <Barchart />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Joining Vs Attrition{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            {/* <div className="card-body-data">
                              <FontAwesomeIcon icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div> */}
                            <LineChart />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Leaves Taken{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>Birthday</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-cake-candles" />
                              <div className="no-data">
                                <p>- Not record found. -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Late Attendance</h4>
                          </div>
                          <div className="card-body">
                            <table
                              className="table border-0 pb-3 admin-dash-table table-hover"
                              id="example"
                            >
                              <thead className="heading">
                                <tr className="to-do">
                                  <th className="">#</th>
                                  <th>Milestone Title</th>
                                  <th>Milestone Cost</th>
                                  <th>Project</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* <tr> */}
                                {/* <td colspan="5" className=""> */}
                                <div className="card-body-data">
                                  <FontAwesomeIcon className="users" icon="fa-solid fa-list" />
                                  <div className="no-data">
                                    <p>- Not enough data -</p>
                                  </div>
                                </div>
                                {/* </td> */}
                                {/* </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* HR tab end */}

                {/* ticket tb start */}
                <div
                  className="tab-pane fade "
                  id="Ticket-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Ticket-tab"
                  tabindex="0"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">
                      <div className="col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Tickets</h5>
                            <div className="number-holder">
                              <p className="number">
                                <div>
                                  <span id="">0</span>
                                </div>
                                <div>
                                  <p>
                                    Unresolved Tickets{" "}
                                    <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />{" "}
                                  </p>
                                </div>
                              </p>
                              <p className="number">
                                <div>
                                  <span id="">0</span>
                                </div>
                                <div>
                                  <p>
                                    Resolved Tickets{" "}
                                    <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />{" "}
                                  </p>
                                </div>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-ticket" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-3">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">
                              Total Unsigned Ticket{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">0</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-ticket" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Type Wise ticket</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>Status Wise Ticket</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>Channer wise Ticket</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>Open Tickets</h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-ticket" />
                              <div className="no-data">
                                <p>- Not record found -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ticket tb end */}

                {/* finance tab start */}
                <div
                  className="tab-pane fade "
                  id="Finance-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Finance-tab"
                  tabindex="0"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">
                      <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Invoices</h5>
                            <div className="number-holder">
                              <p className="number">
                                <div>
                                  <span id="">0</span>
                                </div>
                                <div>
                                  <p>Paid invoices</p>
                                </div>
                              </p>
                              <p className="number">
                                <div>
                                  <span id="">0</span>
                                </div>
                                <div>
                                  <p>Deu invoices</p>
                                </div>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-file-contract" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Finance</h5>
                            <div className="number-holder">
                              <p className="number">
                                <div>
                                  <span id="">$0.00</span>
                                </div>
                                <div>
                                  <p>Total Expenses</p>
                                </div>
                              </p>
                              <p className="number">
                                <div>
                                  <span id="">$0.00</span>
                                </div>
                                <div>
                                  <p>Total Earnings</p>
                                </div>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-coins" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="box bg-white">
                          <div className="text-holder">
                            <h5 className="text">Total Pending Amount</h5>
                            <div className="number-holder">
                              <p className="number">
                                <span id="">$0.00</span>
                              </p>
                            </div>
                          </div>
                          <div className="users">
                            <FontAwesomeIcon icon="fa-solid fa-coins" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Invoice Overview{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Estimate Overview{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              proposal Overview{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-pie" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        {" "}
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Client Wise Earning{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 mt-3">
                        <div className="card">
                          <div className="card-title">
                            <h4>
                              Earning By Project{" "}
                              <FontAwesomeIcon className="users" icon="fa-solid fa-circle-question" />
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="card-body-data">
                              <FontAwesomeIcon className="users" icon="fa-solid fa-chart-simple" />
                              <div className="no-data">
                                <p>- Not enough data -</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* finance tab end */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AdvanceDashboard;
