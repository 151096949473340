import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import JoditEditor from "jodit-react";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

library.add(fas);

const EditOffCanvance = (props) => {
    const { postData, getData } = useContext(Context);
    const editor = useRef(null);
    const id = props.show;

    const [formData, setFormData] = useState({
        name: "",
        description: "",
    });

    const [showModal, setShowModal] = useState({
        code: 0,
        message: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleDescriptionChange = (value) => {
        setFormData({ ...formData, description: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = Validation.FormValidation({
            classname: ".descriptionclass",
        });

        if (validationErrors) {
            try {
                const response = await postData(`/masters/about_us_description/${id}`, formData);

                if (response.success) {
                    setShowModal({ code: response.code, message: response.message });
                    setTimeout(() => {
                        setShowModal(0);
                        props.handleClose();
                    }, 1000);
                } else {
                    setShowModal({ code: response.code, message: response.message });
                    setTimeout(() => {
                        setShowModal(0);
                        props.handleClose();
                    }, 1000);
                }
            } catch (error) {
                console.error("Error submitting form:", error);
            }
        }
    };

    const GetEditData = async () => {
        const response = await getData(`/masters/about_us_description/${id}`);
        setFormData(response?.data);
    };

    useEffect(() => {
        GetEditData();
    }, []);

    const errorStyle = {
        color: "red",
        marginLeft: "5px",
    };

    return (
        <>
            <Offcanvas
                show={props.show}
                style={{ width: "80%" }}
                placement={"end"}
                onHide={props.handleClose}
            >
                <Offcanvas.Header closeButton>
                    {/* <Offcanvas.Title>Edit Category</Offcanvas.Title> */}
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                        <Card.Body>
                            <Card.Title>Edit About Us Description</Card.Title>
                            <hr />
                            <Container>
                                <Row>
                                    <Col md={12}>
                                        <Container>
                                            <Row className="">
                                                <Col xxl={12} xl={12} md={10}>
                                                    <Form
                                                        onSubmit={handleSubmit}
                                                        role="form"
                                                        className="descriptionclass"
                                                    >
                                                        <Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className="main-form-section mt-5">
                                                                        <Row className="justify-content-center">
                                                                            <Form.Label column sm={3}>
                                                                                Name
                                                                            </Form.Label>
                                                                            <Col sm={6}>
                                                                                <Form.Group>
                                                                                    <InputGroup>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            name="name"
                                                                                            value={formData?.name}
                                                                                            onChange={handleChange}
                                                                                            placeholder="Name"
                                                                                            className="input-mandatory"
                                                                                            id="inputEmail3"
                                                                                        />{" "}

                                                                                    </InputGroup>
                                                                                    <span className="error-message"></span>
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="main-form-section mt-5">
                                                                        <Row className="justify-content-center">
                                                                            <Form.Label column sm={3}>
                                                                                Description
                                                                            </Form.Label>
                                                                            <Col sm={6}>
                                                                                <Form.Group>
                                                                                    <InputGroup>
                                                                                        {/* <Form.Control
                                                type="text"
                                                name="description"
                                                value={formData?.description}
                                                onChange={handleChange}
                                                placeholder="Name"
                                                className="input-mandatory"
                                                id="inputEmail3"
                                              />{" "} */}
                                                                                        <JoditEditor
                                                                                            ref={editor}
                                                                                            value={formData.description}
                                                                                            tabIndex={2}
                                                                                            className="custom-jodit-editor"
                                                                                            //onBlur={newContent=>setContent(newContent)}
                                                                                            onChange={handleDescriptionChange}
                                                                                        />
                                                                                    </InputGroup>
                                                                                    <span className="error-message"></span>
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="mt-5 pb-3">
                                                                <div className="d-flex justify-content-center">
                                                                    <Link>
                                                                        <CancelButton
                                                                            name={"cancel"}
                                                                            handleClose={props.handleClose}
                                                                        />
                                                                    </Link>
                                                                    <SaveButton
                                                                        name={"save"}
                                                                        handleSubmit={handleSubmit}
                                                                    />
                                                                </div>
                                                            </Row>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Offcanvas.Body>
            </Offcanvas>
            <ModalSave
                message={showModal.message}
                showErrorModal={showModal.code ? true : false}
            />
        </>
    );
};
export default EditOffCanvance;
