import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";

import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

const AddOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);

  const [country, setCountry] = useState([]);
  const [formData, setFormData] = useState({
    country_id: "",
    name: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e?.name == "country_id") {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".stateclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".stateclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData("/masters/state", formData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetAllCountry = async () => {
    const response = await getData("/allcountry");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "country_id",
          label: data.name,
        });
      });
    }
    setCountry(option);
  };

  useEffect(() => {
    GetAllCountry();
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add State</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add State</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="stateclass"
                          >
                            <Row>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Country{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="country_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        placeholder="Select Country"
                                        value={formData.country_id}
                                        options={country}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      State
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="State "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              {/* </Row> */}

                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>
                              {/* </Col> */}
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
