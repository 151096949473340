import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import JoditEditor from "jodit-react";
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

library.add(fas);

const AddOffCanvance = (props) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const { postData } = useContext(Context);
  const [errors, setErrors] = useState();

  const [formData, setFormData] = useState({
    name: "",
    name2: "",
    image: null,
    image2: null,
    description: "",
    position: "",
    position2: "",
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [imagePreview2, setImagePreview2] = useState(null);
  const [imageError, setImageError] = useState("");
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e.target.type === "file") {
      const selectedImage = e.target.files[0];

      // Check if a file is selected
      if (selectedImage) {
        // Generate image preview and update formData based on the input name
        const reader = new FileReader();
        reader.onloadend = () => {
          if (e.target.name === "image") {
            setImagePreview(reader.result);
            setFormData({ ...formData, image: selectedImage });
          } else if (e.target.name === "image2") {
            setImagePreview2(reader.result);
            setFormData({ ...formData, image2: selectedImage });
          }
        };
        reader.readAsDataURL(selectedImage);
      } else {
        // User canceled image selection, clear the image preview and formData
        if (e.target.name === "image") {
          setImagePreview(null);
          setFormData({ ...formData, image: null });
        } else if (e.target.name === "image2") {
          setImagePreview2(null);
          setFormData({ ...formData, image2: null });
        }
      }
    } else {
      // Handle text input
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Title is required";
    }
    if (!formData.name2) {
      errors.name2 = "Title2 is required";
    }
    if (!formData.image) {
      errors.image = "Image is required";
    }
    if (!formData.image2) {
      errors.image2 = "Image2 is required";
    }
    if (!formData.description) {
      errors.description = "Description is required";
    }
    if (!formData.position) {
      errors.position = "Position is required";
    }
    if (!formData.position2) {
      errors.position2 = "Position2 is required";
    }
    return errors;
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  }

  const handleSubmit = async (e) => {
    e.preventDefault();


    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataWithImage = {
          ...formData,
          imagePath: `/masters/director_profile${formData.image.name}`,
        };
        const formDataUpload = new FormData();
        formDataUpload.append("name", formData.name);
        formDataUpload.append("name2", formData.name2);
        formDataUpload.append("image", formData.image);
        formDataUpload.append("image2", formData.image2);
        formDataUpload.append("description", formData.description);
        formDataUpload.append("position", formData.position);
        formDataUpload.append("position2", formData.position2);

        console.log("FormData content:", formDataUpload);
        const response = await postData(
          "/masters/director_profile",
          formDataUpload
        );

        setShowModal({ code: response?.code, message: response?.message });

        setTimeout(() => {
          setShowModal(0);
          props.handleClose();
        }, 1000);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded ">
            <Card.Body>
              <Card.Title>Director Profile</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={8}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="directorprofileclass"
                            encType="multipart/form-data"
                          >
                            <Row>
                              <Col
                                lg={8}
                                md={10}
                                className=" mx-auto Add-content"
                              >
                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={4}>
                                          Name
                                        </Form.Label>
                                        <Col sm={8}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                placeholder="Name"
                                                id="inputEmail3"
                                                className="input-mandatory"
                                              />
                                            </InputGroup>
                                            {errors?.name && (
                                              <span style={errorStyle}>{errors?.name}</span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={4}>
                                          Name2
                                        </Form.Label>
                                        <Col sm={8}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                name="name2"
                                                value={formData.name2}
                                                onChange={handleChange}
                                                placeholder="Name2"
                                                id="inputEmail3"
                                                className="input-mandatory"
                                              />
                                            </InputGroup>
                                            {errors?.name2 && (
                                              <span style={errorStyle}>{errors?.name2}</span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <div className="main-form-section mt-3">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={4}>
                                      Image
                                    </Form.Label>
                                    <Col sm={8}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="image"
                                            onChange={handleChange}
                                            accept="image/x-png,image/gif,image/jpeg"
                                            style={{ margin: "2px" }}
                                          />
                                        </InputGroup>
                                        {errors?.image && (
                                          <span style={errorStyle}>{errors?.image}</span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    {/* Left side - Image Preview */}
                                    <Col md={8}>
                                      {imagePreview ? (
                                        <img
                                          src={imagePreview}
                                          alt="Image Preview"
                                          className="table-image"
                                          style={{
                                            height: "100px",
                                            width: "150px",
                                            margin: "2px",
                                            marginLeft: "170px",
                                            backgroundSize: "fixed",
                                          }}
                                        />
                                      ) : (
                                        <p style={{ marginLeft: "150px" }}>
                                          No image selected
                                        </p>
                                      )}
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-3">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={4}>
                                      Image
                                    </Form.Label>
                                    <Col sm={8}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="image2"
                                            onChange={handleChange}
                                            accept="image/x-png,image/gif,image/jpeg"
                                            style={{ margin: "2px" }}
                                          />
                                        </InputGroup>
                                        {errors?.image2 && (
                                          <span style={errorStyle}>{errors?.image2}</span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    {/* Left side - Image Preview */}
                                    <Col md={8}>
                                      {imagePreview2 ? (
                                        <img
                                          src={imagePreview2}
                                          alt="Image Preview"
                                          className="table-image"
                                          style={{
                                            height: "100px",
                                            width: "150px",
                                            margin: "2px",
                                            marginLeft: "170px",
                                            backgroundSize: "fixed",
                                          }}
                                        />
                                      ) : (
                                        <p style={{ marginLeft: "150px" }}>
                                          No image selected
                                        </p>
                                      )}
                                    </Col>
                                  </Row>
                                </div>

                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-3">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={4}>
                                          Position
                                        </Form.Label>
                                        <Col sm={8}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                name="position"
                                                value={formData.position}
                                                onChange={handleChange}
                                                placeholder="Position "
                                                id="inputEmail3"
                                                className="input-mandatory"
                                              />
                                            </InputGroup>
                                            {errors?.position && (
                                              <span style={errorStyle}>{errors?.position}</span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-3">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={4}>
                                          Position2
                                        </Form.Label>
                                        <Col sm={8}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                name="position2"
                                                value={formData.position2}
                                                onChange={handleChange}
                                                placeholder="Position2 "
                                                id="inputEmail3"
                                                className="input-mandatory"
                                              />
                                            </InputGroup>
                                            {errors?.position2 && (
                                              <span style={errorStyle}>{errors?.position2}</span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-3">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={4}>
                                          Description
                                        </Form.Label>
                                        <Col sm={8}>
                                          <Form.Group>
                                            <InputGroup>

                                              <JoditEditor
                                                ref={editor}
                                                value={formData.description}
                                                tabIndex={2}
                                                className="custom-jodit-editor"
                                                onBlur={(newContent) =>
                                                  setContent(newContent)
                                                }
                                                onChange={
                                                  handleDescriptionChange
                                                }
                                              />
                                            </InputGroup>
                                            {errors?.description && (
                                              <span style={errorStyle}>{errors?.description}</span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal?.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
