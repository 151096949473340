import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";

import JoditEditor from "jodit-react";

// Import bootstrap(v3 or v4) dependencies

import Offcanvas from "react-bootstrap/Offcanvas";
import $ from "jquery";

import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
const AddOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);
  const editor = useRef(null);

  const [content, setContent] = useState("");
  const [category, setCategory] = useState([]);
  const [commontype, setCommonType] = useState([]);
  const [uses, setUses] = useState([]);
  const [selectCommonType, setSelectCommonType] = useState([]);
  const [selectUses, setSelectUses] = useState([]);
  const [formData, setFormData] = useState({
    category_id: "",
    commontype: "",
    uses: "",
    name: "",
    description: "",
    sub_description: "",
    image: "",
    images: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target || {};
    const { name: selectName, value: selectValue } = e || {};

    if (e?.name === "category_id") {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name || selectName]: value || selectValue,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".subCategoryclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".subCategoryclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        const finalData = new FormData();
        finalData.append("name", formData?.name);
        finalData.append("image", formData?.image);
        finalData.append("description", formData?.description);
        finalData.append("sub_description", formData?.sub_description);
        finalData.append("category_id", formData.category_id?.value);
        finalData.append("uses", formData.uses);
        finalData.append("commontype", formData.commontype);

        if (formData.images) {
          for (let i = 0; i < formData.images.length; i++) {
            finalData.append(`images[${i}]`, formData.images[i]);
          }
        }

        const response = await postData("/masters/subCategory", finalData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleSubDescriptionChange = (value) => {
    setFormData({ ...formData, sub_description: value });
  };

  const handleCommonTypeChange = (value) => {
    setFormData({ ...formData, commontype: value });
  };

  const handleUsesChange = (value) => {
    setFormData({ ...formData, uses: value });
  };
  const GetAllCategory = async () => {
    const response = await getData("/allcategory");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "category_id",
          label: data?.name,
        });
      });
    }
    setCategory(option);
  };

  useEffect(() => {
    window.jQuery = $;
    require("bootstrap/dist/js/bootstrap.bundle.min");

    GetAllCategory();
  }, []);
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add State</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add Product </Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="subCategoryclass"
                          >
                            <Row>
                              <Col md={2}></Col>
                              <Col md={7}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Product category{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="category_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        placeholder="Select Country"
                                        value={formData.category_id}
                                        options={category}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Product
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            // maxLength={20}
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder=" Product "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-3">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      CommonType
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <JoditEditor
                                            ref={editor}
                                            value={formData.commontype}
                                            tabIndex={1}
                                            className="custom-jodit-editor"
                                            onBlur={(newContent) =>
                                              setContent(newContent)
                                            }
                                            onChange={handleCommonTypeChange}
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-3">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Uses
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <JoditEditor
                                            ref={editor}
                                            value={formData.uses}
                                            tabIndex={1}
                                            className="custom-jodit-editor"
                                            onBlur={(newContent) =>
                                              setContent(newContent)
                                            }
                                            onChange={handleUsesChange}
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Image
                                    </Form.Label>

                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="image"
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                [e.target.name]:
                                                  e.target.files[0],
                                              });
                                            }}
                                            accept="image/x-png,image/gif,image/jpeg"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Multiple Images
                                    </Form.Label>

                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="images"
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                [e.target.name]: e.target.files,
                                              });
                                            }}
                                            multiple
                                            accept="image/x-png,image/gif,image/jpeg"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-3">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Description
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <JoditEditor
                                            ref={editor}
                                            value={formData.description}
                                            tabIndex={1}
                                            className="custom-jodit-editor"
                                            onBlur={(newContent) =>
                                              setContent(newContent)
                                            }
                                            onChange={handleDescriptionChange}
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-3">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Sub Description
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <JoditEditor
                                            ref={editor}
                                            value={formData.sub_description}
                                            tabIndex={1}
                                            className="custom-jodit-editor"
                                            onBlur={(newContent) =>
                                              setContent(newContent)
                                            }
                                            onChange={
                                              handleSubDescriptionChange
                                            }
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
