import React, { useContext, useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { CancelButton, SaveButton } from "../../../common/Button";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import JoditEditor from "jodit-react";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData } = useContext(Context);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    image: null,
    description: "",
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState();
  const [descriptionError, setDescriptionError] = useState("");
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e.target.type === "file") {
      const selectedImage = e.target.files[0];

      // Check if a file is selected
      if (selectedImage) {
        // Generate image preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(selectedImage);

        // Update formData with the selected image
        setFormData({ ...formData, image: selectedImage });
      } else {
        // User canceled image selection, clear the image preview and formData
        setImagePreview(null);
        setFormData({ ...formData, image: null });
      }
    } else {
      // Handle text input
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Title is required";
    }
    if (!formData.image) {
      errors.image = "Image is required";
    }
    if (!formData.description) {
      errors.description = "Description is required";
    }
    return errors;
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataWithImage = {
          ...formData,
          imagePath: `/public/upload/newsevents${formData.image.name}`,
        };
        const formDataUpload = new FormData();
        formDataUpload.append("name", formData.name);
        formDataUpload.append("image", formData.image);
        formDataUpload.append("description", formData.description);

        console.log("FormData content:", formDataUpload);
        const response = await postData("/masters/newsevents", formDataUpload);

        setShowModal({ code: response?.code, message: response?.message });

        setTimeout(() => {
          setShowModal(0);
          props.handleClose();
        }, 1000);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded ">
            <Card.Body>
              <Card.Title>Add News & Events</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={6}>
                    <Form
                      onSubmit={handleSubmit}
                      role="form"
                      className="newseventsclass"
                      encType="multipart/form-data"
                    >
                      <Row>
                        <Col lg={12} md={12} className="mx-auto Add-content">
                          {/* Form Data on Left */}
                          <Row>
                            <Col md={12}>
                              <div className="main-form-section mt-5">
                                <Row className="mb-3">
                                  <Form.Label column sm={4}>
                                    News & Events
                                  </Form.Label>
                                  <Col sm={8}>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          name="name"
                                          value={formData.name}
                                          onChange={handleChange}
                                          placeholder="News & Events"
                                          id="inputEmail3"
                                          className="input-mandatory"
                                        />
                                      </InputGroup>
                                      {errors?.name && (
                                        <span style={errorStyle}>{errors?.name}</span>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Form.Label column sm={4}>
                                    Image
                                  </Form.Label>
                                  <Col sm={8}>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="file"
                                          name="image"
                                          onChange={handleChange}
                                          accept="image/x-png,image/gif,image/jpeg"
                                          style={{ margin: "2px" }}
                                        />
                                      </InputGroup>
                                      {errors?.image && (
                                        <span style={errorStyle}>{errors?.image}</span>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Col md={12}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={4}>
                                        Description
                                      </Form.Label>
                                      <Col sm={8}>
                                        <Form.Group>
                                          <InputGroup>
                                            {/* <Form.Control
                                                type="text"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                placeholder="Description "
                                                id="inputEmail3"
                                                className="input-mandatory"
                                              /> */}
                                            <JoditEditor
                                              ref={editor}
                                              value={formData.description}
                                              tabIndex={2}
                                              className="custom-jodit-editor"
                                              onBlur={(newContent) =>
                                                setContent(newContent)
                                              }
                                              onChange={handleDescriptionChange}
                                            />
                                          </InputGroup>
                                          {errors?.description && (
                                            <span style={errorStyle}>{errors?.description}</span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-5 pb-3">
                            <div className="d-flex justify-content-center">
                              <Link>
                                <CancelButton
                                  name={"cancel"}
                                  handleClose={props.handleClose}
                                />
                              </Link>
                              <SaveButton
                                name={"save"}
                                handleSubmit={handleSubmit}
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </Col>

                  {/* Right Column for Image Preview */}
                  <Col md={6} className="text-center">
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Image Preview"
                        className="table-image"
                        style={{
                          height: "200px",
                          width: "300px",
                          margin: "2px",
                          backgroundSize: "cover",
                        }}
                      />
                    ) : (
                      <p>No image selected</p>
                    )}
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal?.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
